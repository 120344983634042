import * as React from 'react';
import CompletePageContent from '../components/CompletePageContent';

const ResetCompletedPage = () => {
  return (
    <CompletePageContent
      title={'パスワードの更新が完了しました！'}
      message={'Qolorのアプリにログインしてください。'}
    />
  );
};

export default ResetCompletedPage;
