import * as React from 'react';
import { FC, CSSProperties } from 'react';
import logo from '../assets/images/qolor_logo_web.png';
import logo_2x from '../assets/images/qolor_logo_web@2x.png';
import logo_3x from '../assets/images/qolor_logo_web@3x.png';

// styles
const pageStyles: CSSProperties = {
  color: '#4F6366',
  padding: '0 20px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
};

const logoStyle: CSSProperties = {
  marginBottom: 40,
  alignSelf: 'center',
};

const headingStyles: CSSProperties = {
  fontSize: 24,
  lineHeight: '41px',
  textAlign: 'center',
};

const paragraphStyles: CSSProperties = {
  fontSize: 20,
  lineHeight: '34px',
  textAlign: 'center',
};

interface CompletePageContentProps {
  title: string;
  message: string;
}

const CompletePageContent: FC<CompletePageContentProps> = ({
  title,
  message,
}) => {
  return (
    <main style={pageStyles}>
      <title>{`${title} - Qolor`}</title>
      <img
        style={logoStyle}
        srcSet={`${logo} 1x, ${logo_2x} 2x, ${logo_3x} 3x`}
        alt="Qolor Logo"
        width={168.79}
        height={287.19}
      />
      <h1 style={headingStyles}>{title}</h1>
      <p style={paragraphStyles}>{message}</p>
    </main>
  );
};

export default CompletePageContent;
